import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"
import * as styles from "./styles.module.scss"
import ProductItem from "../ProductItem"
import getProductImageUrl from "../../../functions/image";

const ProductGrid = ({ products, perLine }) => {

    // console.log('PRODUCTSasd', products)

    return (
        <div className={`${styles.container} ${perLine == 3 ? styles.perLine3 : null}`}>
            {
                products?.map(product => {

                    let destination = '';
                    if (product.destination !== undefined) {
                        destination = product.destination
                    }

                    return <ProductItem
                        className={styles.item}
                        {...product}
                        image_url={getProductImageUrl(product.sku, "260x260")}
                        destination={destination}
                    />
                })
            }
        </div>
    )
    
}

export default ProductGrid