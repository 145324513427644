import React, { useEffect, useRef, useState } from "react"
import { Link, graphql, navigate, parsePath } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sort from "../../components/elements/Sort"
import ProductGrid from "../../components/elements/ProductGrid"
import Pagination from "../../components/elements/Pagination"
import CategoryList from "../../components/elements/CategoryList"
import { API_BASE, PRODUCTS_PER_PAGE } from "../../spectory-config"
import { getProducts } from "../../functions/queries/products"
import getAllProductCategories from "../../functions/queries/productCategories"
import parse from 'html-react-parser';
import { Slider } from "@material-ui/core"


const ArchivePage = (props) => {

    const serverData = props.serverData;

    const [products, setProducts] = useState([]);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchs, setSearchs] = useState('');
    const [showMobileSort, setShowMobileSort] = useState(true);
    const [maxPages, setMaxPages] = useState(serverData.pages);
    const [init, setInit] = useState(true);
    const [initTwo, setInitTwo] = useState(true);
    
    const [priceRange, setPriceRange] = useState([0, 0]);

    let loadedProducts = [];

    useEffect(() => {

        if (serverData.query !== undefined) {
            let sort = 'new';

            if (serverData.query.s === '_price' && serverData.query.so === 'ASC') {
                sort = 'priceup';
            } else if (serverData.query.s === '_price' && serverData.query.so === 'DESC') {
                sort = 'pricedowm';
            }

            setSortedBy(sort);
        }

        setInitTwo(false)

    }, []);

    useEffect(() => {
        setMaxPages(serverData.pages);
    }, [serverData.pages]);

    useEffect(() => {

        if (serverData.filters.maxPrice !== undefined) {
            setPriceRange([serverData.filters.minPrice, serverData.filters.maxPrice])
        }

        serverData.products.forEach((product, key) => {

            loadedProducts.push({
                title: product.title,
                price: product.price,
                destination: `/${product.destination}`,
                sku: product.sku,
                detail: '',
            })

        });

        setProducts(loadedProducts);

        if (serverData.query !== undefined) {
            let queryStringParts = [];
            Object.keys(serverData.query).forEach(key => {
                queryStringParts.push(`${key}=${serverData.query[key]}`);
            })
            setSearchs(queryStringParts.join('&'));
        }

    }, [serverData.products]);

    useEffect(() => {

        if (init === true && initTwo === false){
            setInit(false);
            return;
        }
        if (initTwo === true){
            return;
        }

        let queryArgs = serverData.query || {};

        switch (sortedBy) {
            case 'new':
                queryArgs = {}
                break;

            case 'priceup':
                queryArgs.s = '_price';
                queryArgs.so = 'ASC';
                break;

            case 'pricedowm':
                queryArgs.s = '_price';
                queryArgs.so = 'DESC';
                break;
        }

        let path = window.location.origin + window.location.pathname;

        if (serverData.currentPage !== undefined && serverData.currentPage > 1) {
            path = path.replace('/' + serverData.currentPage, '');
        }

        let queryString = "";
        let queryStringParts = [];
        Object.keys(queryArgs).forEach(key => {
            queryStringParts.push(`${key}=${queryArgs[key]}`);
        })
        queryString = queryStringParts.join('&');

        navigate(`${path}?${queryString}`);

    }, [sortedBy]);

    const priceRangeValueText = (value) => {
        return `€${value}`;
    }


    return (
        <Layout
        >
            <SEO title={serverData.categoryTitle !== undefined ? parse(serverData.categoryTitle) : "Alle Produkte"} />
            <div className={`container`} style={{ paddingTop: 100 }}>
                <div className={`col3`}>
                    <CategoryList current={serverData.currentCategory || ""} categories={serverData.categories} />
                </div>
                <div className={`col9`}>
                    <div>
                        <h1>{serverData.categoryTitle !== undefined ? parse(serverData.categoryTitle) : "Alle Produkte"}</h1>

                        <div className={`sortWrapper ${showMobileSort ? "active" : null}`}>
                            <Sort
                                title="Sortieren nach"
                                value={sortedBy}
                                options={[
                                    {
                                        'title': 'Beliebtheit',
                                        'id': 'new'
                                    },
                                    {
                                        'title': 'Preis aufsteigend',
                                        'id': 'priceup',
                                    },
                                    {
                                        'title': 'Preis Absteigend',
                                        'id': 'pricedowm'
                                    }
                                ]}
                                onChange={(value) => setSortedBy(value)}
                            />
                            {/* <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={priceRange}
                                style={{ width: 300 }}
                                onChange={setPriceRange}
                                valueLabelDisplay="auto"
                                getAriaValueText={priceRangeValueText}
                            /> */}
                        </div>
                        <ProductGrid
                            products={products}
                            perLine={3}
                        />

                        <Pagination
                            current={serverData.currentPage}
                            maxPage={maxPages}
                            pagePath={serverData.baseUrl || 'shop'}
                            search={searchs}
                        />
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default ArchivePage


export async function getServerData(context) {

    const productResponse = await getProducts(context);

    const categories = await getAllProductCategories();

    if (productResponse === false) {
        return {
            status: 404,
            headers: {},
            props: {}
        }
    }

    return {
        props: {
            products: productResponse.products,
            filters: productResponse.filters,
            categories: categories,
            pages: productResponse.pages,
            currentPage: 1,
            query: context.query,
            context: context
        }
    }

}