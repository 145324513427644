import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"
import SortMobile from "./mobile";
import * as styles from "./styles.module.scss"

const Sort = ({ title, value, options, onChange }) => {

    const [active, setActive] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const checkIsMobile = () => {
        setIsMobile(document.documentElement.clientWidth < 992)
    }

    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => {
            document.removeEventListener("resize", checkIsMobile);
        };
    }, []);

    if (isMobile) {
        return <SortMobile
            title={title}
            value={value}
            options={options}
            onChange={onChange}
        />;
    }

    return (
        <div
            className={`${styles.wrapper}`}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <div className={styles.title}>{title}</div>
            <div
                className={`${styles.container} ${active ? styles.active : null}`}
            >
                <div className={styles.title}>{title}</div>
                <div className={styles.contentContainer}>
                    {
                        options.map((option, key) => {
                            return <div
                                className={`${styles.option} ${option.id === value || (value == null && key == 0) ? styles.selected : null}`}
                                onClick={() => onChange(option.id)}
                            >
                                {option.title}
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Sort