import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"
import * as styles from "./styles.module.scss"
import Modal from '../Modal';

const SortMobile = ({ title, value, options, onChange }) => {

    const [active, setActive] = useState(false);
    const [activeOption, setActiveOption] = useState(null);

    const toggleActiveOption = (option) => {
        if (activeOption === option) {
            setActiveOption(null);
        } else {
            setActiveOption(option);
        }
    }

    return (
        <>
            <div
                className={`${styles.mobileOutterWrapper}`}
            >
                <div
                    className={`${styles.container}`}
                    onClick={() => {
                        setActive(true);
                        toggleActiveOption('sort');
                    }}
                >
                    <div className={styles.title}>Sortieren nach</div>
                </div>
            </div>
            <Modal
                state={active}
                close={() => setActive(false)}
                style={{
                    backgroundColor: 'white',
                    padding: 0
                }}
                contentStyle={{
                    marginTop: 50,
                }}
            >
                <div
                    className={`${styles.wrapper}`}
                    onClick={() => toggleActiveOption('sort')}
                >
                    <div
                        className={`${styles.container} ${activeOption === 'sort' ? styles.active : undefined}`}
                    >

                        <div className={styles.title}>Sortieren nach</div>
                        <div className={styles.contentContainer}>
                            {
                                options !== undefined && options.map((option, key) => {
                                    return <div
                                        className={`${styles.option} ${option.id === value || (value == null && key == 0) ? styles.selected : null}`}
                                        onClick={() => {
                                            onChange(option.id);
                                            setActive(false);
                                        }
                                        }
                                    >
                                        {option.title}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default SortMobile