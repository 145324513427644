import Link from "../Link";
import React, { useEffect, useRef, useState } from "react"
import * as styles from "./styles.module.scss"

const Pagination = ({ current, maxPage, pagePath, search }) => {

    let numbers = [];

    if (search !== '') {
        search = '?' + search;
    }

    console.log("maxPage", maxPage);

    if (maxPage < 10) {
        for (let i = 0; i < maxPage; i++) {

            if (i === 0) {

                numbers.push(<Link className={`${styles.number} ${current === (i + 1) ? styles.current : null}`} to={`/${pagePath}${search}`} >{i + 1}</Link>);

            } else {

                numbers.push(<Link className={`${styles.number} ${current === (i + 1) ? styles.current : null}`} to={`/${pagePath}/${i + 1}${search}`} >{i + 1}</Link>);

            }

        }
    } else {


        numbers.push(<Link className={`${styles.number} ${current === (1) ? styles.current : null}`} to={`/${pagePath}${search}`}>{1}</Link>);

        if (current > 4 && current < (maxPage - 2)) {
            numbers.push(' ... ');

            let from = current - 2;

            for (let i = from; i < (from + 5); i++) {
                numbers.push(<Link className={`${styles.number} ${current === (i) ? styles.current : null}`} to={`/${pagePath}/${i}${search}`}>{i}</Link>);
            }

            numbers.push(' ... ');
        } else if (current <= 4) {
            for (let i = 2; i < 6; i++) {
                numbers.push(<Link className={`${styles.number} ${current === (i) ? styles.current : null}`} to={`/${pagePath}/${i}${search}`}>{i}</Link>);
            }
            numbers.push(' ... ');
        } else {
            numbers.push(' ... ');
            for (let i = maxPage - 3; i < maxPage; i++) {
                numbers.push(<Link className={`${styles.number} ${current === (i) ? styles.current : null}`} to={`/${pagePath}/${i}${search}`}>{i}</Link>);
            }
        }

        numbers.push(<Link className={`${styles.number} ${current === (maxPage) ? styles.current : null}`} to={`/${pagePath}/${maxPage}${search}`}>{maxPage}</Link>);
    }



    return (
        <div className={styles.container}>
            {numbers}
        </div>
    )
}

export default Pagination
