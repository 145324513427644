import Link from "../Link";
import React, { useEffect, useRef, useState } from "react"
import * as styles from "./styles.module.scss"
import parse from 'html-react-parser';
import Price from './price';
import { saveToCart } from "../../../functions/cart";
import { strPriceToFloat } from "../../../functions/money";
import CrossIcon from '../../../images/Icons/cross.svg';
import { useDispatch } from 'react-redux'
import { removeFavourite } from "../../../functions/favourite";

const ProductItem = (props) => {

    const dispatch = useDispatch();


    const titleRef = useRef();
    const detailRef = useRef();

    const [titleHeight, setTitleHeight] = useState(null);
    const [detailHeight, setDetailHeight] = useState(null);

    useEffect(() => {

        let titleHeight = 0;
        let detailHeight = 0;
        if (titleRef.current !== undefined) {
            titleHeight = titleRef.current.clientHeight;
        }
        if (detailRef.current !== undefined) {
            detailHeight = detailRef.current.clientHeight;
        }

        if (props.getHeights !== undefined) {
            props.getHeights(titleHeight, detailHeight);
        }

    }, [props.title]);

    useEffect(() => {

        if (titleRef.current !== null) {
            if (props.titleHeight > titleRef.current.clientHeight) {
                setTitleHeight(props.titleHeight);
            } else {
                setTitleHeight(titleRef.current.clientHeight);
            }
        }

    }, [props.titleHeight]);

    useEffect(() => {

        if (detailRef.current !== undefined) {
            if (props.detailHeight > detailRef.current.clientHeight) {
                setDetailHeight(props.detailHeight);
            } else {
                setDetailHeight(detailRef.current.clientHeight);
            }
        }

    }, [props.detailHeight]);


    return (
        <Link to={props.destination} className={`${styles.container} ${props.className}`}>
            {
                props.removeFromFavourite !== undefined &&
                <CrossIcon
                    className={styles.removeFromFavouriteIcon}
                    onClick={(e) => {
                        e.preventDefault();
                        removeFavourite(props.id, dispatch);
                    }}
                />
            }
            <div
                className={styles.imageContainer}
                style={{ backgroundImage: `url(${props.image_url})` }}
            ></div>
            <div className={styles.contentContainer}>
                <h4 ref={titleRef} className={styles.title} style={{ height: titleHeight }}>{parse(props.title)}</h4>
                {props.detail !== '' && <div ref={detailRef} className={styles.detail} style={{ height: detailHeight }}>{parse(props.detail)}</div>}
                <div className={styles.priceContainer}>
                    {
                        props.pricePromotion !== undefined && props.pricePromotion != props.price &&
                        <Price
                            size="extrasmall"
                            value={props.pricePromotion}
                            containerStyle={{ marginTop: 0, marginRight: 20 }}
                            promotion
                        />
                    }
                    <Price
                        size="small"
                        value={props.price}
                        containerStyle={{ marginTop: 0 }}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <div className={styles.showButton}>Anzeigen</div>
                    <div className={`${styles.cartButton} noLink`} onClick={(e) => {
                        e.preventDefault();
                        saveToCart({
                            id: props.id,
                            quantity: 1,
                            price: strPriceToFloat(props.price),
                            title: props.title,
                            detail: props.detail,
                            image: props.image_url,
                            uri: props.destination
                        }, dispatch);
                    }}></div>
                </div>
            </div>

        </Link>
    );


}

export default ProductItem
