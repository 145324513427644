// extracted by mini-css-extract-plugin
export var big = "styles-module--big--SfbCL";
export var buttonContainer = "styles-module--buttonContainer--7Q3i1";
export var cartButton = "styles-module--cartButton--cGmfB";
export var container = "styles-module--container---5MSC";
export var contentContainer = "styles-module--contentContainer--vGJzx";
export var detail = "styles-module--detail--yKTN6";
export var extrasmall = "styles-module--extrasmall--3S9DC";
export var imageContainer = "styles-module--imageContainer--4o6bq";
export var price = "styles-module--price--qNnRK";
export var priceContainer = "styles-module--priceContainer--IVjo5";
export var priceUp = "styles-module--priceUp--cCXNV";
export var removeFromFavouriteIcon = "styles-module--removeFromFavouriteIcon--+Q8LL";
export var showButton = "styles-module--showButton--u3mAf";
export var small = "styles-module--small--4JXuj";
export var title = "styles-module--title--8Fp+0";