import { Link } from "gatsby";
import React, { useRef } from "react"
import * as styles from "./styles.module.scss"

const Price = ({ value, size, containerStyle }) => {

    if (!isNaN(value)) {
        value = value.toString();
        value = value.replace('.', ',');
    }

    let split = value.split(',');
    return (
        <div className={`${styles.price} ${styles[size]}`} style={{ ...containerStyle }}><div className={styles.priceUp}>€</div>{split[0]}<div className={styles.priceUp}>,{split[1]}</div></div>
    );



}

export default Price
