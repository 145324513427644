import React from "react"
import getAllProductCategories from "../../functions/queries/productCategories";
import { API_BASE } from "../../spectory-config"
import ArchivePage from "../shop";

const ArchivePaginationPage = (props) => {
    return <ArchivePage  {...props} />
}

export default ArchivePaginationPage


export async function getServerData(context) {

    var axios = require('axios');
    const perPage = 12;
    console.log("context", context)

    let categoryUri = context.params['*'];
    let categoryUriSplit = categoryUri.split('/');

    let page = 1;
    let categorySlug = categoryUriSplit[categoryUriSplit.length - 1];

    // IF IS A NUMBER = PAGE
    if (!isNaN(categoryUriSplit[categoryUriSplit.length - 1])) {
        page = categoryUriSplit[categoryUriSplit.length - 1];
        categorySlug = categoryUriSplit[categoryUriSplit.length - 2];

        categoryUriSplit.splice(categoryUriSplit.length - 1, 1);
        categoryUri = categoryUriSplit.join('/');
    }


    let data = {
        limit: perPage,
        skip: (page - 1) * perPage
    };

    if (context.query.s !== undefined) {
        data.sort = {
            order: context.query.so,
            by: context.query.s
        }
    }

    data.cat = categorySlug;

    var config = {
        method: 'post',
        url: `${API_BASE}/shop/products`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };

    let response = await axios(config);
    let categories = await getAllProductCategories();

    if (response.data === undefined || response.data.products.length < 1) {
        return {
            status: 404,
            headers: {},
            props: {}
        }
    }

    return {
        props: {
            products: response.data.products,
            filters: response.data.filters,
            pages: Math.ceil(response.data.count / perPage),
            count: response.data.count,
            currentPage: parseInt(page) || 1,
            currentCategory: categorySlug,
            categoryTitle: response.data.categoryTitle,
            categories: categories,
            baseUrl: 'produkt-kategorie/'+categoryUri,
            query: context.query,
        }
    }
}