import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../Link";

import * as styles from "./styles.module.scss";
import formatUri from "../../../functions/formatUri";

const CategoryList = ({ current, categories }) => {

    let categoryList = [];

    categories.nodes.forEach((category, key) => {

        categoryList.push(<li>
            {
                category.children.nodes.length > 0 ?
                    <details className={styles.summary}>
                        <summary><Link className={category.slug === current ? styles.current : null} to={formatUri(category.uri)}>{category.name}</Link></summary>
                        <ul>
                            {
                                category.children.nodes.map(child => {
                                    return <li><Link className={child.slug === current ? styles.current : null} to={formatUri(child.uri)}>{child.name}</Link>
                                        {
                                            child.children.nodes.length > 0 && <ul>
                                                {
                                                    child.children.nodes.map(childOne => {
                                                        return <li><Link className={childOne.slug === current ? styles.current : null} to={formatUri(childOne.uri)}>{childOne.name}</Link></li>
                                                    })
                                                }
                                            </ul>
                                        }
                                    </li>
                                })
                            }
                        </ul>
                    </details>
                    : <Link className={category.slug === current ? styles.current : null} to={formatUri(category.uri)}>{category.name}</Link>
            }

        </li>
        )
    });

    return (
        <ul className={styles.list}>
            {categoryList}
        </ul>
    )
}

export default CategoryList
